import { useUserQuery } from 'graphql/queries/user.graphql';
import router from 'next/router';
import React from 'react';
import { ZOOM_REDIRECT_URL } from 'utils/constants';
import Connection from '../controls/Connection';
import IconZoom from '../icons/IconZoom';

const ZOOM_AUTH_URL =
  'https://zoom.us/oauth/authorize?response_type=code&client_id=' +
  process.env.NEXT_PUBLIC_ZOOM_CLIENT_ID +
  '&redirect_uri=' +
  ZOOM_REDIRECT_URL;

export default function ConnectionZoom(): JSX.Element {
  const [userQueryRes] = useUserQuery({
    requestPolicy: 'cache-only',
  });

  const onConnect = () => {
    router.push(ZOOM_AUTH_URL);
  };

  return (
    <Connection
      icon={IconZoom}
      title="Zoom"
      connected={Boolean(userQueryRes.data?.new_user[0]?.zoomRefreshToken)}
      comingSoon={false}
      onConnect={onConnect}
      onDisconnect={() => null}
    />
  );
}
