import { userEmailAtom } from 'contexts/auth';
import {
  eventIdsPoolAtom,
  serverEventsAtomFamily,
} from 'hooks/events/eventAtoms';
import { atom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { IGridEvent } from 'types/events';
import { isToday } from 'utils/time';

const todayEventsAtom = atom((get) => {
  const calendarId = get(userEmailAtom);

  if (!calendarId) {
    return [];
  }

  return [...get(eventIdsPoolAtom)]
    .map((id) => get(serverEventsAtomFamily(id)))
    .filter(filterTodayEvent)
    .sort((eventA, eventB) => {
      if (eventA.isAllDay) {
        return 1;
      }
      if (eventB.isAllDay) {
        return -1;
      }
      return eventA.startAt > eventB.startAt ? 1 : -1;
    });
});

export function useTodayEvents() {
  return useAtomValue(todayEventsAtom);
}

function filterTodayEvent(event?: IGridEvent | null): event is IGridEvent {
  return (
    !!event &&
    event.belongsToUserCalendar &&
    isToday(event.startAt) &&
    event.status !== 'cancelled'
  );
}
