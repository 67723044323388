import React from 'react';
import { IGridDay } from 'types/events';
import { gridPosition, times } from './utils';

interface Props {
  firstColRef?: React.MutableRefObject<HTMLDivElement | null>;
  firstRowRef?: React.MutableRefObject<HTMLDivElement | null>;
  days: IGridDay[];
}

export default React.memo(function GridLines({
  firstColRef,
  firstRowRef,
  days,
}: Props) {
  return (
    <>
      {times.map((row, index) => (
        <span
          key={`row-${row}`}
          ref={index === 0 ? firstRowRef : undefined}
          className="border-separator pointer-events-none border-b opacity-50"
          style={{
            gridRow: row,
            gridColumn: `${gridPosition.columnStart} / -1`,
          }}
        />
      ))}

      {days.map((_, index) => (
        <span
          key={`col-${index}`}
          ref={index === 0 ? firstColRef : undefined}
          className="border-separator pointer-events-none border-l"
          style={{
            gridRow: `${gridPosition.rowStart} / -1`,
            gridColumn: index + 1 + gridPosition.columnStart,
          }}
        />
      ))}
    </>
  );
});
