import { useUserEmail } from 'contexts/auth';
import { SyncBirthday } from 'hooks/useBirthday';
import { SyncProfile } from 'hooks/useProfile';
import React from 'react';
import PageHeader from './PageHeader';
import ProfileBirthday from './rows/ProfileBirthday';
import ProfileLocation from './rows/ProfileLocation';
import ProfileTwitter from './rows/ProfileTwitter';
import ProfileView from './rows/ProfileView';

export default function SettingsPageProfile(): JSX.Element {
  const email = useUserEmail();

  return (
    <>
      <PageHeader title="Profile" />

      <div className="mb-10">
        <ProfileView />
      </div>

      <div className="flex flex-col space-y-10">
        {email && <ProfileBirthday email={email} />}
        <ProfileLocation />
        <ProfileTwitter />
      </div>

      {email && <SyncProfile email={email} />}
      {email && <SyncBirthday email={email} />}
    </>
  );
}
