import { useUserQuery } from 'graphql/queries/user.graphql';
import router from 'next/router';
import React from 'react';
import Connection from '../controls/Connection';
import IconSpotify from '../icons/IconSpotify';

export default React.memo(function ConnectionSpotify(): JSX.Element {
  const [userQueryRes] = useUserQuery({
    requestPolicy: 'cache-only',
  });

  const onConnect = () => {
    return fetch('/api/spotify/login')
      .then((res) => res.json())
      .then((res) => {
        router.push(res.url);
      });
  };
  return (
    <Connection
      icon={IconSpotify}
      title="Spotify"
      connected={Boolean(userQueryRes.data?.new_user[0]?.spotifyRefreshToken)}
      comingSoon={false}
      onConnect={onConnect}
      onDisconnect={() => null}
      description="Revisit the vibes of your day in your calendar"
    />
  );
});
