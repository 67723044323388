import React from 'react';
import ShortcutKeys from './controls/ShortcutKeys';
import PageHeader from './PageHeader';
import { capitalize } from './utils';
interface Shortcut {
  keyCombos: string[];
  description: string;
}

const shortcutList: Record<string, Shortcut[]> = {
  general: [
    { keyCombos: ['⌘+j', '⌘+k'], description: 'Open quick menu' },
    { keyCombos: ['⌘+/'], description: 'Open shortcuts' },
    { keyCombos: ['⌘+,'], description: 'Go to settings' },
    { keyCombos: ['C'], description: 'Go to calendar' },
    { keyCombos: ['H'], description: 'Go to home' },
    { keyCombos: ['l', '⌘+\\'], description: 'Toggle todo lists sidebar' },
    { keyCombos: ['⌥+m'], description: 'Switch light/dark mode' },
  ],
  calendar: [
    { keyCombos: ['t'], description: 'Go to today' },
    { keyCombos: ['←', '→', '↑', '↓'], description: 'Navigate events' },
    { keyCombos: ['X'], description: 'Select current/upcoming event' },
    { keyCombos: ['m'], description: 'Browse calendar months' },
    { keyCombos: ['←', 'k'], description: 'Go to previous week' },
    { keyCombos: ['→', 'j'], description: 'Go to next week' },
    { keyCombos: ['⇧+↑'], description: "Move event 15' earlier" },
    { keyCombos: ['⇧+↓'], description: "Move event 15' later" },
    { keyCombos: ['enter', 'space'], description: 'Preview focused event' },
    { keyCombos: ['s'], description: 'Create share link' },
    { keyCombos: ['z'], description: 'Change timezone' },
  ],
  events: [
    { keyCombos: ['y'], description: 'RSVP yes' },
    { keyCombos: ['n'], description: 'RSVP no' },
    { keyCombos: ['backspace'], description: 'Delete event' },
    { keyCombos: ['⌥+drag', '⌘+d'], description: 'Duplicate event' },
    { keyCombos: ['⌘+b'], description: 'Bold text' },
    { keyCombos: ['⌘+i'], description: 'Italic text' },
    { keyCombos: ['⌘+drag', '⌘+click'], description: 'Select multiple' },
    { keyCombos: ['1-8'], description: 'Change color' },
  ],
  contacts: [
    { keyCombos: ['A'], description: 'Add contact to sidebar' },
    { keyCombos: ['⇧+1-9'], description: "Show/hide contact's calendar" },
    { keyCombos: ['⇧+esc'], description: 'Hide other calendars' },
  ],
};

export default function SettingsPageShortcuts(): JSX.Element {
  return (
    <div className="group flex flex-col">
      {Object.entries(shortcutList).map(([title, shortcuts]) => {
        return (
          <div key={title} className="mt-10 first:mt-0">
            <div className="mb-5 flex border-b border-gray-200 transition-colors dark:border-gray-750">
              <PageHeader title={capitalize(title)} />
            </div>

            {shortcuts.map((shortcut) => (
              <div
                className="flex justify-between py-3"
                key={shortcut.description}
              >
                <p className="text-base">{shortcut.description}</p>

                <div className="flex justify-between space-x-1 text-base">
                  {shortcut.keyCombos.map((combo, index) => (
                    <React.Fragment key={combo}>
                      <ShortcutKeys key={combo} combo={combo} />
                      {index < shortcut.keyCombos.length - 1 && (
                        <span className="ml-0.5 opacity-40">,</span>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}
