import React from 'react';
import classNames from 'classnames';
import Button from 'joy/Button';

interface Props {
  selected: boolean;
  disabled?: boolean;
  onSelect: () => void;
  onlyButtonClickable?: boolean;
  isCompact?: boolean;
}

export default React.memo(function Switch({
  selected,
  disabled,
  isCompact,
  onlyButtonClickable = true,
  onSelect,
}: Props): JSX.Element {
  const onButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (onlyButtonClickable) onSelect();
  };

  return (
    <Button
      onClick={onButtonClick}
      className={classNames(
        'relative flex shrink-0 cursor-pointer rounded-full',
        {
          'h-4 w-7': isCompact,
          'h-6 w-11': !isCompact,
          'bg-green-500': selected,
          'bg-gray-300 dark:bg-gray-600': !selected,
          'pointer-events-none opacity-40': disabled,
          group: onlyButtonClickable,
        }
      )}
    >
      <span
        className={classNames(
          'absolute flex rounded-full bg-white shadow-sm transition-all',
          {
            'top-0.5 h-3 w-3 group-active:w-4': isCompact,
            'top-1 h-4 w-4 group-active:w-5': !isCompact,
            'left-3.5 group-active:left-2.5': selected && isCompact,
            'left-0.5': !selected && isCompact,
            'left-6 group-active:left-5': selected && !isCompact,
            'left-1': !selected && !isCompact,
          }
        )}
      />
    </Button>
  );
});
