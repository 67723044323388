import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ModalType } from 'types/modal';
import { motion } from 'framer-motion';
import { useIsModalOpen, useUpdateModal } from 'hooks/useModal';
import Input from 'joy/Input';
import Button from 'joy/Button';
import { useUpdateUserStatusByPkMutation } from 'graphql/mutations/UpdateUserStatusByPk.graphql';
import { useUser } from 'contexts/auth';
import Dropdown, { DropdownItem } from 'joy/Dropdown';
import IconCaret from 'components/Icons/IconCaret';
import useUserStatus from 'hooks/useUserStatus';
import { UserStatus } from 'types/status';
import EmojiPicker from 'components/UserPopover/EmojiPicker';
import { EmojiData, getEmojiDataFromNative } from 'emoji-mart';
import data from 'emoji-mart/data/apple.json';
import UserPopoverContent from 'components/UserPopover/UserPopoverContent';
import AvatarStatusIcon from 'joy/AvatarStatusIcon';
import { useFocusTrap } from 'hooks/useFocusTrap';
import IconClear from 'components/Icons/IconClear';
import { hasActiveDefaultStatus } from './utils';
import { isEmpty } from 'lodash';
import Tooltip from 'joy/Tooltip';
import useHotkey from 'hooks/useHotkey';
import { DateTime } from 'luxon';
import Modal from 'components/Modal';

const statusDictionary: Record<UserStatus['status'], string> = {
  free: 'Available',
  busy: 'Busy',
  away: 'Away',
};

export default function ModalSetStatus() {
  const isStatusModalOpen = useIsModalOpen(ModalType.SetStatus);
  const { openModal, closeModal } = useUpdateModal();
  const user = useUser();
  const status = useUserStatus({ userEmail: user?.email });
  const [, updateStatus] = useUpdateUserStatusByPkMutation();
  const [message, setMessage] = useState<string>('');
  const [emojiData, setEmojiData] = useState<EmojiData | null>(null);
  const [statusType, setStatusType] = useState<UserStatus['status']>('free');
  const [clearDate, setClearDate] =
    useState<keyof typeof clearDateMap>('today');

  const popoverRef = useRef<HTMLDivElement | null>(null);
  useFocusTrap({ active: isStatusModalOpen, ref: popoverRef });

  const clearDateMap = useMemo(
    () => ({
      custom: {
        label: status.endAt
          ? DateTime.fromISO(status.endAt).toFormat('HH:mm')
          : '',
        hidden: typeof status.endAt !== 'string',
        getDate: () =>
          status.endAt ? DateTime.fromISO(status.endAt) : undefined,
      },

      thirtyMinutes: {
        label: '30 minutes',
        getDate: () => DateTime.now().plus({ minutes: 30 }),
      },
      oneHour: {
        label: '1 hour',
        getDate: () => DateTime.now().plus({ hours: 1 }),
      },
      fourHours: {
        label: '4 hours',
        getDate: () => DateTime.now().plus({ hours: 4 }),
      },
      today: { label: 'Today', getDate: () => DateTime.now().endOf('day') },
      never: { label: `Don't clear`, getDate: () => undefined },
    }),
    [status.endAt]
  );

  const statusMenuItems = useMemo(() => {
    const items: DropdownItem[] = [
      { type: 'title', value: 'Status' },
      {
        value: 'Available',
        type: 'option',
        selected: statusType === 'free',
        onSelect: () => setStatusType('free'),
        icon: (
          <AvatarStatusIcon
            size={10}
            className="border-none"
            alternate={true}
            status="green"
          />
        ),
      },
      {
        value: 'Busy',
        type: 'option',
        selected: statusType === 'busy',
        onSelect: () => setStatusType('busy'),
        icon: (
          <AvatarStatusIcon
            size={10}
            className="border-none"
            alternate={true}
            status="red"
          />
        ),
      },
      {
        value: 'Away',
        type: 'option',
        selected: statusType === 'away',
        onSelect: () => setStatusType('away'),
        icon: (
          <AvatarStatusIcon
            size={10}
            className="border-none"
            alternate={true}
            status="gray"
          />
        ),
      },
    ];

    return items;
  }, [statusType]);

  const timeMenuItems = useMemo(() => {
    const items: DropdownItem[] = Object.entries(clearDateMap)
      .filter(([key]) => key !== 'custom')
      .map(([key, value]) => ({
        value: value.label,
        type: 'option',
        selected: clearDate === key,
        onSelect: () => setClearDate(key as typeof clearDate),
      }));

    items.unshift({ type: 'title', value: 'Duration' });

    if (!clearDateMap.custom.hidden) {
      items.unshift({ type: 'separator' });
      items.unshift({
        value: clearDateMap.custom.label,
        type: 'option',
        selected: clearDate === 'custom',
        onSelect: () => setClearDate('custom'),
      });
      items.unshift({ type: 'title', value: 'Current' });
    }

    return items;
  }, [clearDate, clearDateMap]);

  useEffect(() => {
    setMessage(status.message || '');
  }, [status.message]);

  useEffect(() => {
    setStatusType(status.status);
  }, [status.status]);

  useEffect(() => {
    setClearDate(status.endAt ? 'custom' : 'today');
  }, [status.endAt]);

  useEffect(() => {
    if (isEmpty(status.emoji)) {
      setEmojiData(null);
      return;
    }
    const emojiData = getEmojiDataFromNative(
      status.emoji as string,
      'apple',
      data
    );
    setEmojiData(emojiData);
  }, [status.emoji]);

  useHotkey('u', { scope: 'global' }, (event) => {
    event.preventDefault();
    openModal(ModalType.SetStatus);
  });

  useHotkey('u', { scope: 'modal', enabled: isStatusModalOpen }, (event) => {
    event.preventDefault();
    closeModal(ModalType.SetStatus);
  });

  return (
    <Modal id={ModalType.SetStatus}>
      <motion.div
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        ref={popoverRef}
        initial={{ y: -12, opacity: 0, scale: 0.96 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: -12, opacity: 0, scale: 0.96 }}
        transition={{ duration: 0.08 }}
        className="pointer-events-none fixed top-0 left-0 z-100 flex h-full w-full items-center justify-center"
      >
        <div
          className="pointer-events-auto z-100 flex w-full space-x-8 rounded-xl bg-white p-6 shadow-quickmenu transition-colors dark:bg-gray-750"
          style={{ width: 600 }}
        >
          <div className="flex flex-1 flex-col">
            <p className="mb-5 text-xl font-semibold">Set a status</p>

            <div className="flex flex-col space-y-2">
              <small className="text-secondary mb-0.5 text-[11px] font-black uppercase leading-snug tracking-wider transition-colors">
                What&#39;s going on?
              </small>
              <div className="relative flex w-full">
                <div className="absolute top-0 left-0 flex h-full w-10 items-center justify-center">
                  <EmojiPicker emoji={emojiData} onEmojiChange={setEmojiData} />
                </div>

                {hasActiveDefaultStatus({
                  message,
                  emoji:
                    emojiData && 'native' in emojiData
                      ? emojiData.native
                      : undefined,
                }) && (
                  <div className="absolute top-0 right-0 flex h-full w-11 items-center justify-center ">
                    <Tooltip content="Clear status">
                      <Button
                        onClick={() => {
                          setMessage('');
                          setEmojiData(null);
                          setClearDate('today');
                        }}
                        className="scale-100 text-gray-400 transition hover:scale-105 hover:text-gray-500 active:scale-100 dark:text-gray-400 dark:hover:text-gray-300"
                      >
                        <IconClear />
                      </Button>
                    </Tooltip>
                  </div>
                )}

                <Input
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={true}
                  spellCheck={false}
                  placeholder="Building fun products"
                  value={message}
                  onChange={(event) => setMessage(event.currentTarget.value)}
                  className="w-full rounded-lg border border-transparent bg-gray-150 px-9 py-2.5 text-sm font-medium outline-none transition-colors dark:bg-gray-700 dark:focus:border-gray-600"
                />
              </div>
            </div>

            <div className="my-6 flex w-full flex-1 space-x-4">
              <div className="flex flex-1 flex-col space-y-2">
                <small className="text-secondary mb-0.5 text-[11px] font-black uppercase leading-snug tracking-wider transition-colors">
                  Show as
                </small>

                <Dropdown items={statusMenuItems} fullWidth={true}>
                  <Button className="group flex w-full flex-grow items-center rounded-lg border border-transparent bg-gray-150 px-3 py-2.5 text-sm font-medium outline-none !transition-colors hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:border-gray-600">
                    <AvatarStatusIcon
                      size={10}
                      alternate={true}
                      className="mr-1.5 border-none"
                      alternateClassName="bg-gray-150 dark:bg-gray-700"
                      status={
                        statusType === 'free'
                          ? 'green'
                          : statusType === 'busy'
                          ? 'red'
                          : 'gray'
                      }
                    />

                    {statusDictionary[statusType]}

                    <div className="text-icons ml-auto flex transition-colors group-hover:text-gray-500 dark:group-hover:text-gray-400">
                      <IconCaret />
                    </div>
                  </Button>
                </Dropdown>
              </div>

              <div className="flex flex-1 flex-col space-y-2">
                <small className="text-secondary mb-0.5 text-[11px] font-black uppercase leading-snug tracking-wider transition-colors">
                  Clear after
                </small>

                <Dropdown items={timeMenuItems} fullWidth={true}>
                  <Button className="group flex w-full flex-grow items-center rounded-lg border border-transparent bg-gray-150 px-3 py-2.5 text-sm font-medium outline-none !transition-colors hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:border-gray-600">
                    {clearDateMap[clearDate].label}

                    <div className="text-icons ml-auto flex transition-colors group-hover:text-gray-500 dark:group-hover:text-gray-400">
                      <IconCaret />
                    </div>
                  </Button>
                </Dropdown>
              </div>
            </div>

            <div className="ml-auto mt-auto flex space-x-1">
              <Button
                className="flex h-10 items-center justify-center rounded-lg px-5 text-sm font-semibold leading-snug text-gray-600 ring-inset ring-gray-100 hover:text-gray-900 focus-visible:ring dark:text-gray-300 dark:ring-gray-700 dark:hover:text-gray-100"
                onClick={() => closeModal(ModalType.SetStatus)}
              >
                Cancel
              </Button>

              <Button
                className="flex h-10 items-center justify-center rounded-lg bg-green-500 px-5 text-sm  font-semibold leading-snug text-white ring-inset ring-white/20 hover:bg-green-600 focus-visible:ring dark:bg-green-600 dark:hover:bg-green-500"
                onClick={() => {
                  closeModal(ModalType.SetStatus);
                  updateStatus({
                    userEmail: user?.email || '',
                    _set: {
                      emoji:
                        emojiData && 'native' in emojiData
                          ? emojiData.native
                          : null,
                      status: statusType,
                      message,
                      endAt:
                        clearDateMap[clearDate]?.getDate()?.toISO() || null,
                    },
                  });
                }}
              >
                Save
              </Button>
            </div>
          </div>

          {user && (
            <div
              tabIndex={-1}
              className="pointer-events-none ml-auto flex flex-col space-y-2 self-center"
            >
              <UserPopoverContent
                showNote={false}
                user={{ ...user, avatar: user.photo }}
                status={{
                  userEmail: user.email,
                  userId: user.uid,
                  status: statusType,
                  message,
                  emoji:
                    emojiData && 'native' in emojiData
                      ? emojiData.native
                      : undefined,
                  integrationAmieEventId: 'placeholder',
                  integrationAmieStartAt: DateTime.now()
                    .minus({ minutes: 15 })
                    .toISO(),
                  integrationAmieEndAt: DateTime.now()
                    .plus({ minutes: 45 })
                    .toISO(),
                }}
              />
            </div>
          )}
        </div>
      </motion.div>

      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.08 }}
        className="fixed top-0 left-0 z-50 flex h-full w-full items-center justify-center bg-black/75"
        onClick={() => closeModal(ModalType.SetStatus)}
      />
    </Modal>
  );
}
