import { useEffect } from 'react';
import { useDndContext } from '@dnd-kit/core';
import { useHotkeyContext } from 'contexts/hotkey';
import { DraggableType } from 'types/drag-and-drop';

export function UseDraggingScope() {
  useDraggingScope();
  return null;
}

function useDraggingScope(): void {
  const { setScope, setPreviousScope } = useHotkeyContext();
  const context = useDndContext();

  useEffect(() => {
    if (context.active?.data.current?.type === DraggableType.TODO) {
      setScope('sorting');
    } else {
      setPreviousScope();
    }
  }, [context.active, setPreviousScope, setScope]);
}
