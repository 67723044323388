import { EventCreator } from '@graphql-types@';
import { mergeProps, useUpdateEffect } from '@react-aria/utils';
import { AnimatePresence } from 'framer-motion';
import { isReactText } from 'joy/utils';
import React, { useMemo } from 'react';
import { useHover, useLayer } from 'react-laag';
import type { Placement } from 'react-laag';
import useUserStatus from 'hooks/useUserStatus';
import UserPopoverContent from './UserPopoverContent';
import PrefetchSingleEvent from './PrefetchSingleEvent';
import { SyncProfile } from 'hooks/useProfile';
import { SyncProfileFollow } from 'hooks/useProfileFollow';
import { useRouter } from 'next/router';
import { useModalValue } from 'hooks/useModal';

interface Props {
  user: Pick<EventCreator, 'id' | 'email' | 'displayName' | 'avatar'>;
  children: React.ReactChild;
  placement?: Placement;
  disabled?: boolean;
  arbitraryContent?: React.ReactElement;
}

export default React.memo(function UserPopover({
  user,
  children,
  placement = 'bottom-start',
  disabled = false,
  arbitraryContent,
}: Props): JSX.Element {
  const currentOpenModal = useModalValue();
  const { asPath } = useRouter();

  const userStatus = useUserStatus({ userEmail: user.email });
  const [isOver, hoverProps, close] = useHover({
    delayEnter: 100,
    delayLeave: 100,
  });
  const isOpen = isOver && disabled === false;
  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen,
    placement,
    possiblePlacements: [
      'left-start',
      'right-start',
      'bottom-start',
      placement,
    ],
    auto: true,
    triggerOffset: 8,
    ResizeObserver,
  });

  const popoverProps = useMemo(
    () => ({
      onMouseDown: close,
    }),
    [close]
  );

  let trigger;

  if (isReactText(children)) {
    trigger = (
      <span
        {...mergeProps(triggerProps, hoverProps, popoverProps)}
        ref={triggerProps.ref}
      >
        {children}
      </span>
    );
  } else {
    trigger = React.cloneElement(children, {
      ...mergeProps(children.props, triggerProps, hoverProps, popoverProps),
      ref: triggerProps.ref,
    });
  }

  useUpdateEffect(() => {
    close();
  }, [asPath, currentOpenModal]);

  return (
    <>
      {trigger}
      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            // renderLayer should only run under the same condition as isOpen provided to useLayer
            // https://github.com/everweij/react-laag/issues/52#issuecomment-746146753
            // https://github.com/everweij/react-laag#quick-start
            <UserPopoverContent
              user={user}
              status={userStatus}
              arbitraryContent={arbitraryContent}
              {...layerProps}
              {...hoverProps}
            />
          )}
        </AnimatePresence>
      )}

      {/* "Leaf" components which don't render any contents */}
      {userStatus.integrationAmieEventId && user.email && (
        <>
          {user.email && (
            <SyncProfile key={`profile-${user.email}`} email={user.email} />
          )}
          {user.email && (
            <SyncProfileFollow
              key={`follow-${user.email}`}
              email={user.email}
            />
          )}
          <PrefetchSingleEvent
            eventId={userStatus.integrationAmieEventId}
            calendarId={user.email}
          />
        </>
      )}
    </>
  );
});
