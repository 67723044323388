/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useCallback, useEffect } from 'react';
import Modal from 'components/Modal';
import { ModalType } from 'types/modal';
import useHotkey from 'hooks/useHotkey';
import { useIsModalOpen, useUpdateModal } from 'hooks/useModal';
import useUserAgent from 'hooks/useUserAgent';
import useSetNavItems from './hooks/useSetNavItems';
import QuickMenuContent from './QuickMenuContent';
import { perfStart } from 'contexts/performance';
import { WorkerProvider } from 'contexts/worker';
import { useElectronIpcEvent } from 'hooks/useElectronIpc';
import useTrappedMode from './hooks/useTrappedMode';
import usePrevious from 'hooks/usePrevious';

export default function QuickMenu(): JSX.Element {
  const isQuickMenuOpen = useIsModalOpen(ModalType.QuickMenu);
  const { openModal, closeModal } = useUpdateModal();
  const { isMac } = useUserAgent();
  const { setTrappedMode } = useTrappedMode();
  const { addNavItem } = useSetNavItems();
  const { resetNavItems } = useSetNavItems();
  const isQuickMenuOpenPrev = usePrevious(isQuickMenuOpen);

  const openQuickMenu = useCallback(() => {
    perfStart('open-quick-menu');
    openModal(ModalType.QuickMenu);
    addNavItem();
  }, [addNavItem, openModal]);

  const closeQuickMenu = useCallback(() => {
    closeModal(ModalType.QuickMenu);
    resetNavItems();
  }, [closeModal, resetNavItems]);

  useElectronIpcEvent('quickmenu:toggle', () => {
    if (isQuickMenuOpen) {
      closeQuickMenu();
    } else {
      openQuickMenu();
    }
  });

  useElectronIpcEvent('quickmenu:close', () => {
    closeQuickMenu();
  });

  useHotkey(
    isMac ? 'escape, command+j, command+k' : 'escape, ctrl+j, ctrl+k',
    { scope: 'quickmenu', enabledWithinInput: true },
    (event) => {
      event.preventDefault();
      closeQuickMenu();
    }
  );

  useHotkey(
    isMac ? 'command+j, command+k' : 'ctrl+j, ctrl+k',
    {
      scope: 'all',
      enabledWithinInput: true,
      enabledWithinContentEditable: true,
    },
    (event) => {
      openQuickMenu();
      event.preventDefault();
    }
  );

  useEffect(() => {
    if (isQuickMenuOpenPrev && !isQuickMenuOpen) {
      // Don't let menu contents change until modal fadeout is done
      setTimeout(() => setTrappedMode(undefined), 200);
    }
  }, [isQuickMenuOpen, isQuickMenuOpenPrev, setTrappedMode]);

  return (
    <Modal id={ModalType.QuickMenu} scope="quickmenu">
      <div className="fixed top-0 left-0 z-100 flex h-full w-full items-center justify-center">
        <WorkerProvider>
          <QuickMenuContent />
        </WorkerProvider>
        <span
          className="fixed top-0 left-0 z-50 flex h-full w-full items-center justify-center bg-black/75"
          onClick={() => closeModal(ModalType.QuickMenu)}
        />
      </div>
    </Modal>
  );
}
