import { useUserEmail } from 'contexts/auth';
import useProfile, { useProfileUpdate } from 'hooks/useProfile';
import React from 'react';
import ExternalLink from '../controls/ExternalLink';
import Input from '../controls/Input';
import PageControlHeader from '../PageControlHeader';

export default React.memo(function ProfileTwitter(): JSX.Element {
  const userEmail = useUserEmail();
  const profile = useProfile(userEmail || '');
  const updateProfile = useProfileUpdate(userEmail || '');

  return (
    <div className="flex flex-col">
      <PageControlHeader
        title="Twitter"
        description={
          <>
            Your{' '}
            <ExternalLink href="https://www.twitter.com/">Twitter</ExternalLink>{' '}
            handle. Visible to everyone.
          </>
        }
      />

      <Input
        placeholder="e.g @elonmusk"
        value={profile?.twitter || ''}
        onSubmit={(value) =>
          updateProfile({ twitter: value.replace(/@/g, '') })
        }
      />
    </div>
  );
});
