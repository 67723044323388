import { CalendarStartsOn_Enum } from '@graphql-types@';
import classNames from 'classnames';
import dateStyles from 'components/EventPopover/DateTimePicker/EventDate.module.css';
import { CustomHeader } from 'components/EventPopover/DateTimePicker/EventDateTimePicker';
import React, { useMemo, useState } from 'react';
import { NormalizedPreferences } from 'utils/format';
import { DateTime } from 'luxon';
import { enUS } from 'date-fns/locale';
import dynamic from 'next/dynamic';
import { createPortal } from 'react-dom';
import type { Placement } from 'popper.js';

const DatePicker = dynamic(() => import('react-datepicker'));

const PopperContainer = ({ children }: { children: React.ReactNode }) =>
  createPortal(
    <div className={classNames('flex w-full', dateStyles.datepicker)}>
      {children}
    </div>,
    document.body
  );

interface Props {
  date: string | null;
  children: React.ReactNode;
  setDate?: (date: Date) => void;
  preferences?: NormalizedPreferences;
  placement?: Placement;
}

export function WidgetBirthdayPicker({
  date,
  setDate,
  preferences,
  placement = 'bottom',
  children,
}: Props) {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDate, setCalendarPreviewDate] = useState<DateTime | null>(
    date ? DateTime.fromFormat(date, 'yyyy-MM-dd') : null
  );

  const weekStartsOn = useMemo(() => {
    if (!preferences) return 0;

    const preferredStartDay = preferences.calendarStartsOn;
    switch (preferredStartDay) {
      case CalendarStartsOn_Enum.Monday:
        return 1;
      case CalendarStartsOn_Enum.Sunday:
        return 0;

      default:
        return 0;
    }
  }, [preferences]);

  return (
    <DatePicker
      value={date || undefined}
      dateFormat="yyyy-MM-dd"
      wrapperClassName="w-full h-full"
      open={isCalendarOpen}
      popperContainer={PopperContainer}
      onClickOutside={() => setIsCalendarOpen(false)}
      onCalendarOpen={() => setIsCalendarOpen(true)}
      onCalendarClose={() => setIsCalendarOpen(false)}
      renderCustomHeader={CustomHeader}
      popperPlacement={placement}
      selected={selectedDate?.toJSDate()}
      onChange={(date: Date) =>
        setCalendarPreviewDate(DateTime.fromJSDate(date))
      }
      locale={{
        ...enUS,
        options: {
          ...enUS.options,
          weekStartsOn,
        },
      }}
      onSelect={setDate}
      customInput={children}
      dayClassName={() => 'text-secondary text-sm font-medium'}
    />
  );
}
