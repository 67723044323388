import classNames from 'classnames';
import { usePreferences, useUpdatePreference } from 'hooks/usePreferences';
import Tooltip from 'joy/Tooltip';
import { capitalize } from 'lodash';
import React from 'react';
import { PreferenceName } from 'types/preference';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import PageControlHeader from '../PageControlHeader';

export default React.memo(function EventColor(): JSX.Element {
  const preferences = usePreferences();
  const updatePreference = useUpdatePreference();

  return (
    <div className="flex flex-col">
      <PageControlHeader
        title="Base color"
        description="Set the default base color of events on creation."
        comingSoon={false}
      />

      <div className="flex justify-between">
        {Object.entries(EVENT_COLOR_MAP).map(([id, colorProp]) => (
          <Tooltip key={id} content={capitalize(id)} targetOffset={12}>
            <button
              className="relative flex items-center justify-center"
              name={`color-${id}`}
              onClick={() =>
                updatePreference(PreferenceName.DefaultEventColor, id)
              }
            >
              <span
                className={classNames(
                  'h-14 w-14 scale-100 transform rounded-full border-4 transition-transform hover:scale-105 active:scale-100',
                  colorProp.colorSwatchBg,
                  colorProp.border,
                  colorProp.ring,
                  {
                    'ring-4 ring-opacity-40 ring-offset-2 ring-offset-white dark:ring-offset-gray-800':
                      id === preferences.defaultEventColor,
                  }
                )}
              />
            </button>
          </Tooltip>
        ))}
      </div>
    </div>
  );
});
