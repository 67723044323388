import { NewEventRsvpEnum } from '@graphql-types@';
import type { EventConfirmation } from 'hooks/useEventConfirmations';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import { rrulestr } from 'rrule';
import { Attendee, EventDiff, EventDiffValue, IGridEvent } from 'types/events';
import sanitizeHtml from 'xss';

interface LabelProps {
  event: EventConfirmation['event'];
  hasDiff: boolean;
  guestsTotal: number;
  isRecurring: boolean;
  hasExistingAttendees: boolean;
  type: EventConfirmation['type'];
}

export function getConfirmationLabel({
  isRecurring,
  type,
  guestsTotal,
}: LabelProps): string {
  if (type === 'create') {
    let title = 'Create';
    if (guestsTotal === 0) title += ' event';
    if (guestsTotal === 1) title += ' & send invite';
    if (guestsTotal > 1) title += ` & send ${guestsTotal} invites`;

    return title;
  }

  if (type === 'update') {
    let title = 'Edit';
    if (guestsTotal === 0 && !isRecurring) title += ' event';
    if (guestsTotal === 0 && isRecurring) title += ' recurring event';
    if (guestsTotal === 1) title += ' & send invite';
    if (guestsTotal > 1) title += ` & send ${guestsTotal} invites`;

    return title;
  }

  return isRecurring ? 'Delete recurring event' : 'Delete event';
}

export function getConfirmationDescription({
  type,
  hasDiff,
  guestsTotal,
  isRecurring,
  hasExistingAttendees,
}: LabelProps): string {
  if (type === 'delete') {
    return 'Are you sure you want to delete this event?';
  }

  if (!hasExistingAttendees && guestsTotal === 0 && hasDiff && isRecurring) {
    return 'Apply changes to this or all future events.';
  }

  if (!hasExistingAttendees && guestsTotal === 0 && hasDiff) {
    return 'Apply changes to this event only.';
  }

  if (hasExistingAttendees && guestsTotal === 0 && hasDiff && isRecurring) {
    return 'Apply changes to this or all future events, and notify guests.';
  }

  if (hasExistingAttendees && guestsTotal === 0 && hasDiff) {
    return 'Apply changes to this event, and notify guests.';
  }

  if (guestsTotal === 1) {
    return 'The guest will be notified and invited to the event.';
  }

  return `${guestsTotal} invitees will be notified and invited to the event.`;
}

export function getButtonLabel(
  { hasDiff, type, guestsTotal }: LabelProps,
  short = false
): string {
  if (guestsTotal > 0 && !hasDiff) {
    return `Send invite${guestsTotal === 1 ? '' : 's'}`;
  }

  if (type === 'create') {
    return short ? 'Create' : 'Create event';
  }

  if (type === 'delete') {
    return short ? 'Delete' : 'Delete event';
  }

  return short ? 'Update' : 'Update event';
}

export function getDiffKeyLabel(key: keyof IGridEvent): string {
  switch (key) {
    case 'title':
      return 'Title';
    case 'startAt':
      return 'Start time';
    case 'endAt':
      return 'End time';
    case 'location':
      return 'Location';
    case 'isAllDay':
      return 'All day';
    case 'description':
      return 'Description';
    case 'recurrenceRules':
      return 'Recurrence';
    case 'attendees':
      return 'Guests';
    case 'colorFamily':
      return 'Color';
    default:
      return key;
  }
}

function isDiffAttendees(
  key: string,
  _value: EventDiffValue
): _value is Attendee[] {
  return key === 'attendees';
}

export function sanitizeDescription(description: string): string {
  const markup = sanitizeHtml(description, {
    whiteList: {
      a: ['href', 'target', 'rel'],
      p: [],
      span: [],
      li: [],
      ul: [],
      ol: [],
      strong: [],
      div: [],
      em: [],
      s: [],
      u: [],
      b: [],
      i: [],
      mark: [],
      sub: [],
      sup: [],
    },
  });

  return markup.replace(/<[^>]*>?/gm, '');
}

export function getDiffValue(
  key: keyof IGridEvent,
  value: EventDiffValue,
  timeFormat: string
): string | Attendee[] {
  if (key === 'rsvp') {
    if (value === NewEventRsvpEnum.Yes) return 'Going';
    if (value === NewEventRsvpEnum.No) return 'Not going';
    if (value === NewEventRsvpEnum.NotInvited) return 'Not going';
    if (value === NewEventRsvpEnum.Unknown) return 'Unknown';
  }

  if (key === 'colorFamily' && typeof value === 'string') {
    return capitalize(value);
  }

  if (typeof value === 'string') return value;
  if (typeof value === 'number') return value.toString();
  if (value instanceof DateTime) return value.toFormat(timeFormat);
  if (isDiffAttendees(key, value) && Array.isArray(value)) {
    return value;
  }

  if (key === 'recurrenceRules') {
    if (Array.isArray(value) && value.length === 0) return 'None';
    if (Array.isArray(value))
      return capitalize(rrulestr(value.join('\n')).toText());
  }
  return '';
}

export interface DiffEntry {
  label: string;
  old: string | Attendee[];
  new: string | Attendee[];
}

export function getAttendeeDiff(diff: EventDiff | undefined): {
  added: Attendee[];
  removed: Attendee[];
} {
  let added: Attendee[] = [];
  let removed: Attendee[] = [];

  if (!diff || !diff.attendees) return { added, removed };
  const oldValue = isDiffAttendees('attendees', diff.attendees.old)
    ? diff.attendees.old
    : [];
  const newValue = isDiffAttendees('attendees', diff.attendees.new)
    ? diff.attendees.new
    : [];

  added = newValue.filter(
    (attendee) =>
      !oldValue.some((oldAttendee) => oldAttendee.id === attendee.id)
  );
  removed = oldValue.filter(
    (attendee) =>
      !newValue.some((newAttendee) => newAttendee.id === attendee.id)
  );

  return { added, removed };
}

export function getDiffArray(diff: EventDiff, timeFormat: string): DiffEntry[] {
  const hasBothStartAndEnd = diff.startAt && diff.endAt;

  return Object.entries(diff)
    .map(([key, value]) => {
      const endAt = diff['endAt'];
      if (key === 'attendees' || (key === 'endAt' && hasBothStartAndEnd))
        return undefined;

      if (key === 'startAt' && endAt && hasBothStartAndEnd) {
        const didDayChange =
          value.old instanceof DateTime && value.new instanceof DateTime
            ? value.old.ordinal !== value.new.ordinal
            : false;

        const fullFormat = didDayChange ? `dd MMM, ${timeFormat}` : timeFormat;

        const oldStartAtValue = getDiffValue(key, value.old, fullFormat);
        const newStartAtValue = getDiffValue(key, value.new, fullFormat);

        const oldEndAtValue = getDiffValue(key, endAt.old, timeFormat);
        const newEndAtValue = getDiffValue(key, endAt.new, timeFormat);

        return {
          label: 'Time',
          old: `${oldStartAtValue} - ${oldEndAtValue}`,
          new: `${newStartAtValue} - ${newEndAtValue}`,
        };
      }

      const entry: DiffEntry = {
        label: key,
        old: getDiffValue(key as keyof IGridEvent, value.old, timeFormat),
        new: getDiffValue(key as keyof IGridEvent, value.new, timeFormat),
      };

      return entry;
    })
    .filter((item): item is DiffEntry => item !== undefined);
}
