import { EventCreator } from '@graphql-types@';
import { HTMLMotionProps, motion } from 'framer-motion';
import Avatar from 'joy/Avatar';
import React from 'react';
import {
  getStatusText,
  hasActiveDefaultStatus,
  hasActiveAmieIntegration,
} from 'components/Status/utils';
import classNames from 'classnames';
import AmieIntegrationStatus from './AmieIntegrationStatus';
import { UserStatus } from 'types/status';
import { AMIE_PATTERN_BG_HEIGHT } from 'utils/constants';
import Link from 'next/link';
import ProfileFollowToggle from './ProfileFollowToggle';
import { useUserEmail } from 'contexts/auth';
import { isSameDomain } from 'utils/parsing';
import SectionNote from './SectionNote';

interface Props extends HTMLMotionProps<'div'> {
  user: Pick<EventCreator, 'id' | 'email' | 'displayName' | 'avatar'>;
  status: UserStatus;
  showNote?: boolean;
  arbitraryContent?: React.ReactElement;
}

export default React.forwardRef(function UserPopoverContent(
  { user, status, showNote = true, arbitraryContent, ...props }: Props,
  ref: React.Ref<HTMLDivElement>
): JSX.Element {
  const ownEmail = useUserEmail();
  const isSelf = ownEmail === user.email;
  const isExternalUser = !isSameDomain(ownEmail || '', user.email || '');
  const profileLink = `/${user.email}`;

  return (
    <motion.div
      ref={ref}
      className="bg-dropdown relative z-100 flex w-60 cursor-default flex-col rounded-xl px-5 pt-6 pb-5 shadow-quickmenu"
      initial={{ opacity: 0, y: 4 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 4 }}
      transition={{ duration: 0.12 }}
      onClick={(event) => event.stopPropagation()}
      {...props}
    >
      <Link href={profileLink}>
        <div className="fade bg-dropdown fade-bg absolute top-0 left-0 h-12 w-full cursor-pointer overflow-hidden rounded-t-xl">
          <div
            className="bg-pattern-amie h-full w-full animate-backgroundScroll bg-repeat-y"
            style={{
              height: AMIE_PATTERN_BG_HEIGHT * 3,
              backgroundSize: '155%',
              backgroundPositionX: '5%',
            }}
          />
        </div>
      </Link>

      {user.email && (
        <div className="absolute top-0 right-0 z-20 p-3">
          <ProfileFollowToggle
            isSelf={isSelf}
            isExternalUser={isExternalUser}
            compact={true}
            email={user.email}
          />
        </div>
      )}

      <div className="flex flex-col">
        <div className="relative -ml-1.5 flex w-full flex-col">
          <Link href={profileLink}>
            <div className="relative flex grow-0 cursor-pointer self-start rounded-full bg-white p-1.5 dark:bg-gray-900">
              <Avatar
                name={user.displayName || ''}
                src={user.avatar}
                size={56}
                className="pointer-events-none"
                alternateStatus={true}
              />
            </div>
          </Link>
          <div className="absolute bottom-0 left-11 z-20 flex h-7 w-full">
            <div
              className={classNames(
                'flex justify-start rounded-full border-4 border-white bg-gradient-to-t px-2 py-0.5 text-[11px] font-black text-white dark:border-gray-900',
                {
                  'from-green-600 to-green-500': status?.status === 'free',
                  'from-red-600 to-red-500': status?.status === 'busy',
                  'from-gray-600 to-gray-500': status?.status === 'away',
                }
              )}
            >
              {isExternalUser ? `¯\\_(ツ)_/¯` : getStatusText(status)}
            </div>
          </div>
        </div>

        <div className="mt-2 flex w-full flex-col">
          <Link href={profileLink}>
            <p className="cursor-pointer self-start font-semibold hover:opacity-80">
              {user.displayName}
            </p>
          </Link>
          {hasActiveDefaultStatus(status) && !arbitraryContent && (
            <p className="my-0.5 text-xs font-medium transition-height line-clamp-2 hover:line-clamp-none">
              {status?.emoji ? `${status?.emoji} ` : ''}
              {status?.message}
            </p>
          )}
          <p className="text-secondary text-xs font-medium">{user?.email}</p>
        </div>

        <hr className="my-3 border-gray-100 dark:border-gray-800" />

        <div className="flex w-full flex-col gap-2">
          {!arbitraryContent && showNote && user.email && (
            <SectionNote email={user.email} />
          )}

          {!arbitraryContent && hasActiveAmieIntegration(status) && (
            <AmieIntegrationStatus status={status} />
          )}
          {arbitraryContent ? arbitraryContent : null}
        </div>
      </div>
    </motion.div>
  );
});
