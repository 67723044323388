import { usePreferences, useUpdatePreference } from 'hooks/usePreferences';
import RangeSlider from 'joy/RangeSlider';
import React, { useState } from 'react';
import { PreferenceName } from 'types/preference';
import PageControlHeader from '../PageControlHeader';

export default React.memo(function EventDuration(): JSX.Element {
  const preferences = usePreferences();
  const updatePreference = useUpdatePreference();
  const preferenceName = PreferenceName.DefaultDuration;
  const preferenceValue = preferences?.defaultDuration;
  const [currentValue, setCurrentValue] = useState<number>(
    preferenceValue || 0
  );

  return (
    <>
      <PageControlHeader
        title="Default duration"
        description="Drag the bar left or right to set the duration, in minutes, for events on creation."
      />
      <RangeSlider
        min={15}
        max={60}
        step={5}
        value={currentValue}
        displayValue={`${currentValue} minutes`}
        onChange={setCurrentValue}
        onSubmit={(value) => {
          updatePreference(preferenceName, value.toString());
        }}
      />
    </>
  );
});
