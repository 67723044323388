import { useUser } from 'contexts/auth';
import { useCalendarHistorySubscription } from 'graphql/subscriptions/calendarHistory.graphql';
import { useExtendedFetchEvents } from 'hooks/events/useGridEvents';
import useCalendar from 'hooks/useCalendar';
import { useEagerCalendars } from 'hooks/useCalendarsEagerly';
import { useVisibleCalendarIds } from 'hooks/useVisibleCalendars';
import useWindowFocus from 'hooks/useWindowFocus';
import { uniq } from 'lodash';
import React, { useEffect } from 'react';

export const SubscribeForCalendarsUpdates = React.memo(
  function SubscribeForCalendarsUpdatesContent() {
    const calendarIds = useVisibleCalendarIds();
    const eagerCalendars = useEagerCalendars();
    const dedupedCalendarIds = uniq([...calendarIds, ...eagerCalendars]); // filter out duplicates

    return (
      <>
        {dedupedCalendarIds.map((calendarId) => (
          <SubscribeForCalendar calendarId={calendarId} key={calendarId} />
        ))}
      </>
    );
  }
);

const SubscribeForCalendar = React.memo(function SubscribeForCalendar(props: {
  calendarId: string;
}): null {
  const user = useUser();
  const { startDate, endDate } = useCalendar();
  const pause = !user?.didAuth;
  const { didRefocus } = useWindowFocus();
  const refreshCalendarEvents = useExtendedFetchEvents();
  const [subscriptionRes] = useCalendarHistorySubscription({
    variables: {
      calendarIds: [props.calendarId],
    },
    pause,
  });
  const calendarUpdatedAt = subscriptionRes.data?.calendarHistory[0]?.updatedAt;

  useEffect(() => {
    /**
     * Refetch events if subscription event arrives about a calendar update.
     * or if startDate or endDate changes
     */
    refreshCalendarEvents(props.calendarId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarUpdatedAt, startDate.toMillis(), endDate.toMillis()]);

  useEffect(() => {
    /**
     * Refetch events if window regains focus. Handle manually since
     * this doesn't get fetched with urql "provider".
     */
    if (didRefocus) {
      refreshCalendarEvents(props.calendarId);
    }
  }, [didRefocus, props.calendarId, refreshCalendarEvents]);

  return null;
});
