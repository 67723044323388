import React from 'react';
import NavigationItem from './NavigationItem';
import Avatar from 'joy/Avatar';
import useUserStatus from 'hooks/useUserStatus';
import { useUser, useUserEmail } from 'contexts/auth';
import { getAvatarStatus } from 'components/Status/utils';
import UserPopover from 'components/UserPopover';
import Button from 'joy/Button';
import IconSmile from 'components/Icons/IconSmile';
import { useUpdateModal, useUpdateSettingPageModal } from 'hooks/useModal';
import { ModalType } from 'types/modal';
import IconCalendar from 'components/Icons/IconCalendar';
import { SettingsPageType } from 'components/Settings/types';
import IconCog from 'components/Icons/IconCog';
import classNames from 'classnames';

export default React.memo(function UserItem() {
  const { openModal } = useUpdateModal();
  const setSettingsPage = useUpdateSettingPageModal();
  const user = useUser();
  const userStatus = useUserStatus({ userEmail: user?.email });

  if (!user) return null;

  return (
    <UserPopover
      placement="right-start"
      arbitraryContent={<SelfPopoverContent />}
      user={{
        ...user,
        avatar: user.photo,
      }}
    >
      <NavigationItem
        onClick={() => {
          setSettingsPage(SettingsPageType.Profile);
          openModal(ModalType.Settings);
        }}
      >
        <Avatar
          alternateStatus={true}
          status={getAvatarStatus(userStatus.status)}
          name={user.displayName || ''}
          src={user.photo}
          size={20}
          statusClassName="border-2 border-background group-hover:border-gray-150 dark:group-hover:border-gray-750"
        />
      </NavigationItem>
    </UserPopover>
  );
});

const sharedUserItemButtonStyles =
  'flex w-full items-center rounded-lg px-3.5 py-2 gap-2 text-s font-medium hover:bg-gray-100 dark:hover:bg-gray-800';

function SelfPopoverContent(): JSX.Element {
  const { openModal } = useUpdateModal();
  const userEmail = useUserEmail();
  const userStatus = useUserStatus({ userEmail });
  const setSettingsPage = useUpdateSettingPageModal();

  const statusClass = `w-full text-left ${userStatus.emoji ? 'pr-4' : ''}`;
  return (
    <div className="-ml-4 -mb-4 -mt-2" style={{ width: 'calc(100% + 32px)' }}>
      <Button
        onClick={() => openModal(ModalType.SetStatus)}
        className={classNames(
          sharedUserItemButtonStyles,
          userStatus.emoji ? 'items-start' : 'items-center',
          'break-words'
        )}
      >
        {!userStatus.emoji && !userStatus.message ? (
          <>
            <IconSmile className="h-4 w-4 text-gray-500 dark:text-gray-400" />
            <span className={statusClass}>Set a custom status</span>
          </>
        ) : (
          <>
            {userStatus.emoji && (
              <span className="mr-2">{userStatus.emoji}</span>
            )}

            {userStatus.message && (
              <span className={statusClass}>{userStatus.message}</span>
            )}
          </>
        )}
      </Button>

      <Button
        className={sharedUserItemButtonStyles}
        onClick={() => {
          openModal(ModalType.Settings);
          setSettingsPage(SettingsPageType.Accounts);
        }}
      >
        <IconCalendar className="h-4 w-4 text-gray-500 dark:text-gray-400" />
        Calendars
      </Button>

      <Button
        className={sharedUserItemButtonStyles}
        onClick={() => {
          openModal(ModalType.Settings);
          setSettingsPage(SettingsPageType.Profile);
        }}
      >
        <IconCog className="h-4 w-4 text-gray-500 dark:text-gray-400" />
        Settings
      </Button>
    </div>
  );
}
