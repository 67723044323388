import React, { useCallback } from 'react';
import { formatTime } from 'components/Grid/utils';
import { DateTime } from 'luxon';
import { formatShareLink, getHttpShareLink } from 'utils/share';
import { getTimeFormat } from 'utils/format';
import { useUi24HourClock } from 'hooks/usePreferences';
import reactElementToJSXString from 'react-element-to-jsx-string';
import * as clipboard from 'clipboard-polyfill';
import { trackEvent } from 'utils/analytics';
import { EventName } from 'types/analytics';
import useToaster from 'joy/Toasts/useToaster';
import { CalendarSlotDate } from 'hooks/calendarLink/booking/atoms';
import { getShortTimeZone } from 'utils/time';
import useTimezone from 'hooks/useTimeZone';

export default function useCopyShareLink() {
  const { add } = useToaster();
  const ui24HourClock = useUi24HourClock();
  const timeFormatPreference = getTimeFormat(ui24HourClock);
  const timezone = useTimezone();

  const copyLink = useCallback(
    (id: string, alias?: string) => {
      const shareLink = formatShareLink({ id, alias });
      navigator.clipboard.writeText(shareLink);
      trackEvent(EventName.CopiedSlotsLink);
      add({
        id: 'share-link-copy-link',
        label: 'Link copied!',
        description: shareLink,
        kind: 'success',
      });
    },
    [add]
  );

  const copySlots = useCallback(
    (id: string, slots: Omit<CalendarSlotDate, 'calendarLinkId'>[]) => {
      const grouped: {
        date: DateTime;
        slots: Omit<CalendarSlotDate, 'calendarLinkId'>[];
      }[] = [];
      for (let index = 0; index < slots.length; index++) {
        const slot = slots[index];
        const formattedSlotStart = slot.startAt;

        const groupIndexToAdd = grouped.findIndex(
          (group) => group && group.date.hasSame(formattedSlotStart, 'day')
        );
        if (groupIndexToAdd >= 0) {
          grouped[groupIndexToAdd].slots.push(slot);
        } else {
          // First item for date...
          const itemForGroup = {
            date: slot.endAt,
            slots: [slot],
          };
          grouped[index] = itemForGroup;
        }
      }

      if (!slots || slots.length === 0) return null;

      const shortTimeZone =
        slots.length === 0
          ? ''
          : getShortTimeZone(slots[0].startAt.setZone(timezone));

      const component = (
        <div>
          Does one of these times ({shortTimeZone}) work for you?
          {grouped.map((group) => {
            const day = group.date.toFormat('ccc, dd MMM:');

            return (
              <div key={`slot-preview-${day}`}>
                {day}
                {group.slots.map((slot) => {
                  const startAt = slot.startAt.setZone(timezone);
                  const endAt = slot.endAt.setZone(timezone);
                  const linkHref = `${getHttpShareLink(
                    id
                  )}?t=${startAt.toSeconds()}`;

                  return (
                    <p key={'time-preview' + slot.id}>
                      •
                      <a href={linkHref} target="_blank" rel="noreferrer">
                        {formatTime(timeFormatPreference, startAt)} -{' '}
                        {formatTime(timeFormatPreference, endAt)}
                      </a>
                    </p>
                  );
                })}
              </div>
            );
          })}
        </div>
      );

      const htmlText = reactElementToJSXString(component).trim();
      const plainText = htmlText
        .replace(/•/gm, '')
        .replace(/<[^>]+>/gm, '')
        .replace(/(^[ \t]*\n)/gm, '');

      const item = new clipboard.ClipboardItem({
        'text/html': new Blob([htmlText], {
          type: 'text/html',
        }),
        'text/plain': new Blob([plainText], {
          type: 'text/plain',
        }),
      });
      clipboard.write([item]);

      trackEvent(EventName.CopiedSlotsText);
      add({
        id: 'share-link-copy-plain',
        label: 'Slots copied!',
        description: `${slots.length} ${
          slots.length === 1 ? 'slot' : 'slots'
        } copied as text`,
        kind: 'success',
      });
    },
    [timezone, add, timeFormatPreference]
  );

  return { copySlots, copyLink };
}
