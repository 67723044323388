import { useUi24HourClock } from 'hooks/usePreferences';
import useTimezone, {
  maxNumTimezones,
  timezonePreferencesAtom,
} from 'hooks/useTimeZone';
import { useAtomValue } from 'jotai/utils';
import React from 'react';
import { getTimezoneHoursDiff } from 'utils/time';
import { getTimeLabel, gridPosition, times } from './utils';

export default React.memo(function GridLabels(): JSX.Element {
  const timezone = useTimezone();
  const timezonePreferences = useAtomValue(timezonePreferencesAtom);

  // The timezones are rendered from left to right, but we keep
  // the primary at index 0. So we actually want to display them
  // as if index 0 is at the end of the list, therefore we
  // reverse the order of timezones when displaying.
  const timezoneOffsets = timezonePreferences
    .map((timezonePreference) =>
      timezonePreference.timezone
        ? getTimezoneHoursDiff(timezone, timezonePreference.timezone)
        : 0
    )
    .reverse();

  return (
    <GridLabelsWithOffset
      offsets={timezoneOffsets}
      allTimezoneSlotsTaken={timezonePreferences.length === maxNumTimezones}
    />
  );
});

export const GridLabelsWithOffset = React.memo(function GridLabelsWithOffset({
  offsets,
  allTimezoneSlotsTaken = false,
}: {
  offsets: number[];
  allTimezoneSlotsTaken?: boolean;
}): JSX.Element {
  const ui24HourClock = useUi24HourClock();

  return (
    <>
      {times.map((time, i) => (
        <div
          key={i}
          className="pointer-events-none relative -top-2 flex select-none pr-1.5 text-right font-medium text-gray-400 dark:text-gray-500"
          style={{
            gridRow: i + 1 + gridPosition.rowStart,
            gridColumn: gridPosition.labels,
            paddingLeft: allTimezoneSlotsTaken ? undefined : '16px',
          }}
        >
          {offsets.map((offset, j) => (
            <small key={j + '_' + (time + offset)} className="w-full text-xs">
              {getTimeLabel(time + offset, { ui24HourClock })}
            </small>
          ))}
        </div>
      ))}
    </>
  );
});

GridLabelsWithOffset.displayName = 'GridLabelsWithOffset';
