import React from 'react';

export default function IconZoom(): JSX.Element {
  return (
    <svg
      className="h-8 w-8"
      viewBox="0 0 472.4 472.4"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="236.2" cy="236.2" fill="#4a8cff" r="236.2" />
      <path
        d="m84.65 162.25v111a45.42 45.42 0 0 0 45.6 45.2h161.8a8.26 8.26 0 0 0 8.3-8.2v-111a45.42 45.42 0 0 0 -45.6-45.2h-161.75a8.26 8.26 0 0 0 -8.35 8.2zm226 43.3 66.8-48.8c5.8-4.81 10.3-3.6 10.3 5.1v148.8c0 9.9-5.5 8.7-10.3 5.09l-66.8-48.69z"
        fill="#fff"
      />
    </svg>
  );
}
