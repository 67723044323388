import React from 'react';
import IconSmile from '../icons/IconSmile';

export default React.memo(function ComingSoon(): JSX.Element {
  return (
    <div className="flex flex-col flex-grow items-center justify-center w-full rounded-xl bg-gray-50 dark:bg-gray-700/10 min-h-[400px]">
      <div className="flex flex-col space-y-2.5 items-center justify-center text-secondary opacity-20">
        <IconSmile />
        <p className="font-bold text-lg uppercase tracking-wider">
          Coming soon
        </p>
      </div>
    </div>
  );
});
