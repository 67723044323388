import React from 'react';
import PageHeader from './PageHeader';
import ComingSoon from './rows/ComingSoon';

export default function SettingsPageDownload(): JSX.Element {
  return (
    <>
      <PageHeader title="Download apps" />

      <ComingSoon />
    </>
  );
}
