import { usePreferences, useUpdatePreference } from 'hooks/usePreferences';
import React from 'react';
import { PreferenceName } from 'types/preference';
import SwitchGroup from '../controls/SwitchGroup';
import PageControlHeader from '../PageControlHeader';

export default React.memo(function EventPrivacy(): JSX.Element {
  const preferences = usePreferences();
  const updatePreference = useUpdatePreference();
  const preferenceName = PreferenceName.TodoPrivacy;
  const preferenceValue = preferences?.todoPrivacy; // false indicates "public", "true" - private

  return (
    <SwitchGroup
      selected={preferenceValue === true}
      onSelect={() =>
        updatePreference(
          preferenceName,
          preferenceValue === true ? 'false' : 'true'
        )
      }
    >
      <PageControlHeader
        title="New events are private by default"
        description="If enabled, your events will be private. Others see private events as 'Busy'."
        noPadding={true}
      />
    </SwitchGroup>
  );
});
