import React, { useCallback, useRef, useState } from 'react';
import useFeedback from 'hooks/useFeedback';
import Button from 'joy/Button';
import useToaster from 'joy/Toasts/useToaster';
import { useUserEmail } from 'contexts/auth';
import useProfile from 'hooks/useProfile';
import classNames from 'classnames';
import { useCmdKey } from 'hooks/useHotkey';

interface Props {
  onSubmit?: () => void;
  onCancel?: () => void;
  className?: string;
  showHeader?: boolean;
}

export default function FeedbackContext({
  onSubmit,
  onCancel,
  className,
  showHeader = true,
}: Props): JSX.Element | null {
  const isCmdKeyPressed = useCmdKey();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { sendFeedback } = useFeedback();
  const [feedback, setFeedback] = useState('');
  const { add } = useToaster();
  const email = useUserEmail();
  const profile = useProfile(email);

  const onClickSubmit = useCallback(() => {
    if (feedback.trim().length > 0) {
      sendFeedback({ feedback }).then(() =>
        add({
          id: 'send-feedback-success',
          label: `Thank you ${
            profile?.firstName ? `${profile.firstName}` : email
          }!`,
          description: 'We received your feedback',
          kind: 'success',
        })
      );

      if (textareaRef.current) textareaRef.current.value = '';
      setFeedback('');
    }

    if (onSubmit) {
      onSubmit();
    }
  }, [add, email, feedback, onSubmit, profile, sendFeedback]);

  return (
    <div
      className={classNames(
        'z-100 flex h-full w-full flex-col overflow-y-scroll text-lg font-semibold',
        className
      )}
    >
      {showHeader && <div>Send feedback</div>}

      <textarea
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={true}
        ref={textareaRef}
        rows={4}
        className="text-primary my-4 min-w-0 flex-1 resize-none rounded-lg border border-transparent bg-gray-150 p-3 text-sm font-medium placeholder-gray-400 outline-none dark:bg-gray-700 dark:focus:border-gray-600"
        placeholder="Tell us anything..."
        onChange={(event) => setFeedback(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && isCmdKeyPressed) {
            onClickSubmit();
          } else if (event.key === 'Escape' && textareaRef.current != null) {
            textareaRef.current.blur();
          } else if ((event.key ===  'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') && feedback.trim().length !== 0) {
            event.stopPropagation();
          }
        }}
      />

      <div className="flex flex-row justify-end justify-self-end">
        {onCancel != null && (
          <Button
            onClick={onCancel}
            className="h-10 px-5 text-sm font-semibold leading-snug text-gray-600 hover:text-gray-900 focus-visible:ring dark:text-gray-300 dark:ring-gray-700 dark:hover:text-gray-100"
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={onClickSubmit}
          disabled={feedback.trim().length === 0}
          className="h-10 rounded-lg bg-green-500 px-5 text-sm font-semibold leading-snug text-white hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-500 "
        >
          Send
        </Button>
      </div>
    </div>
  );
}
