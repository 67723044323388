import { ColorFamily, NewEventRsvpEnum } from '@graphql-types@';
import classNames from 'classnames';
import {
  getAmieMeetingStatus,
  getAmieStatusTitle,
  getAmieEndText,
  getAmieStartText,
} from 'components/Status/utils';
import { useSingleEventQuery } from 'graphql/queries/singleEvent.graphql';
import React from 'react';
import { IGridEvent } from 'types/events';
import { UserStatus } from 'types/status';
import { colorOrDefault, EVENT_COLOR_MAP } from 'utils/eventColors';
import AmieIntegrationStatusLoader from './AmieIntegrationStatusLoader';
import { DateTime } from 'luxon';
import { useAccentColor } from 'hooks/usePreferences';
import {
  useCalendarColor,
  usePrimaryColor,
} from 'components/Panels/useAvailableCalendars';

interface Props {
  status: UserStatus;
}

export const placeholderEvent: Omit<
  IGridEvent,
  | 'calendarId'
  | 'createdAt'
  | 'startAt'
  | 'endAt'
  | 'dayIndex'
  | 'schedule'
  | 'visibility'
> = {
  id: 'placeholderEvent',
  title: 'Changing my status',
  isAllDay: false,
  videoConferences: [],
  description: '',
  isOwnEvent: true,
  isSelfAsAttendee: true,
  allOtherGuestsDeclined: false,
  attendees: [],
  location: '',
  colorFamily: ColorFamily.Pink,
  canEdit: true,
  belongsToUserCalendar: true,
  prevEndAt: DateTime.now(),
  prevStartAt: DateTime.now(),
  rsvp: NewEventRsvpEnum.Yes,
  status: 'confirmed',
  isDraft: false,
};

export default function AmieIntegrationInfo({ status }: Props) {
  const [queryResults] = useSingleEventQuery({
    variables: {
      eventId: status.integrationAmieEventId || '',
      calendarId: status.userEmail,
    },
    requestPolicy: 'cache-and-network',
    pause:
      status.integrationAmieEventId === undefined ||
      status.integrationAmieEventId === 'placeholder',
  });

  const event =
    status.integrationAmieEventId === 'placeholder'
      ? placeholderEvent
      : queryResults.data?.singleEvent;

  const calendarColor = usePrimaryColor();
  const color = event?.colorFamily || calendarColor;
  const colorMap = EVENT_COLOR_MAP[color];
  const eventTitle = queryResults.fetching ? undefined : event?.title || 'Busy';

  const meetingStatus = getAmieMeetingStatus(
    status,
    (event?.attendees.length ?? 1) > 1
  );

  if (!meetingStatus || meetingStatus === 'ended') {
    return null;
  }

  return (
    <div className="flex w-full flex-col space-y-0.5 truncate">
      <small className="text-secondary text-[11px] font-black uppercase leading-tight tracking-wider">
        {getAmieStatusTitle(meetingStatus)}
      </small>
      {eventTitle ? (
        <div className="flex items-center">
          <span
            className={classNames(
              'mr-1.5 flex h-2 w-2 shrink-0 rounded-full',
              colorMap.solidBg
            )}
          />
          <p className="truncate text-s font-medium">{eventTitle}</p>
        </div>
      ) : (
        <div className="flex items-center">
          <AmieIntegrationStatusLoader />
        </div>
      )}

      <p className="text-secondary text-xs font-medium">
        {meetingStatus === 'planned'
          ? getAmieStartText(status.integrationAmieStartAt)
          : getAmieEndText(status.integrationAmieEndAt)}
      </p>
    </div>
  );
}
