import React, { useEffect, useRef } from 'react';
import Modal from 'components/Modal';
import { ModalType } from 'types/modal';
import { motion } from 'framer-motion';
import { useIsModalOpen, useUpdateModal } from 'hooks/useModal';
import { useFocusTrap } from 'hooks/useFocusTrap';
import ModalEventConfirmationContent from './ModalEventConfirmationContent';
import { useEventConfirmations } from 'hooks/useEventConfirmations';
import classNames from 'classnames';

export default React.memo(function ModalEventConfirmation() {
  const { openModal, closeModal } = useUpdateModal();
  const eventConfirmations = useEventConfirmations();
  const hasMessages = eventConfirmations.length > 0;

  const isConfirmationModalOpen = useIsModalOpen(ModalType.EventConfirmation);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  useFocusTrap({ active: isConfirmationModalOpen, ref: popoverRef });

  useEffect(() => {
    if (hasMessages) {
      openModal(ModalType.EventConfirmation);
    } else {
      closeModal(ModalType.EventConfirmation);
    }
  }, [closeModal, hasMessages, openModal]);

  return (
    <Modal id={ModalType.EventConfirmation} blockClose={true}>
      <motion.div
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        ref={popoverRef}
        initial={{ y: -12, opacity: 0, scale: 0.96 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: -12, opacity: 0, scale: 0.96 }}
        transition={{ duration: 0.08 }}
        className="pointer-events-none fixed top-0 left-0 z-200 flex h-full w-full items-center justify-center"
      >
        <div className="relative flex items-center justify-center">
          {eventConfirmations.map((confirmation, index) => {
            const isVisible = index === 0;

            return (
              <motion.div
                key={index}
                layout={true}
                className={classNames(
                  'pointer-events-auto flex w-full flex-col rounded-xl bg-white shadow-quickmenu transition-colors',
                  {
                    'relative dark:bg-gray-750': isVisible,
                    'absolute dark:bg-gray-800': !isVisible,
                  }
                )}
                style={{
                  zIndex: 100 - index,
                  width: 500 - index * 30,
                  bottom: -index * 10,
                  minHeight: 100,
                }}
              >
                {isVisible && (
                  <ModalEventConfirmationContent {...confirmation} />
                )}
              </motion.div>
            );
          })}
        </div>
      </motion.div>

      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.08 }}
        className="fixed top-0 left-0 z-50 flex h-full w-full items-center justify-center bg-black/75"
      />
    </Modal>
  );
});
