import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import Input from 'joy/Input';
import { EVENT_PLACEHOLDER_LOCATION } from 'utils/events';
import Dropdown from 'joy/Dropdown';
import { EventColor } from 'types/color';
import { AnimatePresence } from 'framer-motion';
import GoToLocationButton from './GoToLocationButton';
import ContextMenu, {
  ContextMenuType,
  useUpdateContextMenu,
} from 'joy/ContextMenu';
import { useLocationAutoComplete } from './useLocation';

interface LocationDropdownProps {
  value?: string;
  isLoading: boolean;
  inputClassName?: string;
  colorMap: EventColor;
  hasFocus?: boolean;
  className?: string;
  isDraft?: boolean;
  locationButtonClassName?: string;
  onChange: (value: string | undefined) => void;
  onSave?: () => void;
}

export default function LocationDropdown({
  onChange,
  onSave,
  value,
  isLoading,
  isDraft,
  inputClassName,
  colorMap,
  hasFocus,
  className,
  locationButtonClassName,
}: LocationDropdownProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { openContextMenu, closeContextMenu } = useUpdateContextMenu();

  const onDropdownItemSelect = useCallback(
    async (item: string) => {
      onChange(item);

      // save is needed to attach a Google Meet link
      // we run this for all locations however for consistency
      // and to avoid users having to guess when their location gets saved on selection
      onSave?.();
    },
    [onChange, onSave]
  );

  const { getDropdownItems, getContextMenuItems } = useLocationAutoComplete({
    value,
    onDropdownItemSelect,
    isDraft,
  });

  const isPlaceholder = isLoading || isDraft;

  return (
    <ContextMenu
      items={getContextMenuItems}
      onClose={() => closeContextMenu(ContextMenuType.Location)}
      placement={'right-start'}
      className="w-full"
    >
      <div
        onClickCapture={() => openContextMenu(ContextMenuType.Location)}
        className="w-full"
      >
        <Dropdown
          items={getDropdownItems}
          menuClassName={classNames(`max-h-40 w-64`, className)}
          className="flex w-full"
        >
          <div className="flex w-full items-center">
            <Input
              ref={inputRef}
              value={value}
              readOnly={true}
              placeholder={EVENT_PLACEHOLDER_LOCATION}
              spellCheck={false}
              className={
                inputClassName ||
                classNames(
                  'w-full truncate bg-transparent text-sm font-medium outline-none ',
                  'cursor-default',
                  'caret-transparent', // hide caret to show that text can't be edited
                  { ['text-gray-500 dark:text-gray-400']: isPlaceholder },
                  colorMap.placeholder
                )
              }
            />
            <AnimatePresence>
              {!hasFocus && (
                <div className={locationButtonClassName}>
                  <GoToLocationButton
                    location={value}
                    visible={value !== '' && !isPlaceholder}
                  />
                </div>
              )}
            </AnimatePresence>
          </div>
        </Dropdown>
      </div>
    </ContextMenu>
  );
}
