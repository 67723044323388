import Button from 'joy/Button';
import React from 'react';
import classNames from 'classnames';
import useProfileFollow, {
  useProfileFollowUpdate,
} from 'hooks/useProfileFollow';
import useProfile from 'hooks/useProfile';

interface Props {
  email: string;
  isExternalUser: boolean;
  isSelf: boolean;
  compact?: boolean;
}

export default React.memo(function ProfileFollowToggle({
  email,
  isExternalUser,
  isSelf,
  compact = false,
}: Props) {
  const profile = useProfile(email);
  const isFollowing = useProfileFollow(profile?.contactId || '');
  const { toggleFollowing } = useProfileFollowUpdate(
    profile?.contactId || '',
    email
  );

  if (isExternalUser || isSelf) return null;

  return (
    <Button
      onClick={toggleFollowing}
      className={classNames(
        'group self-start rounded-lg border font-semibold',
        {
          'bg-dropdown px-2.5 py-1.5 text-[11px]': compact,
          'px-6 py-3 text-sm': !compact,
          'border-gray-150 hover:bg-gray-150 dark:border-gray-600 dark:hover:bg-gray-600':
            isFollowing,
          'border-transparent bg-gray-200 dark:bg-gray-700':
            !isFollowing && compact,
          'border-transparent bg-green-500 text-white dark:bg-green-600':
            !isFollowing && !compact,
        }
      )}
    >
      <span className="opacity-100 group-hover:opacity-0">
        {isFollowing ? 'Following' : 'Follow'}
      </span>
      <span className="absolute left-0 w-full opacity-0 group-hover:opacity-100">
        {isFollowing ? 'Unfollow' : 'Follow'}
      </span>
    </Button>
  );
});
