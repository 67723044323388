import { DateTime } from 'luxon';
import { usePreferences, useUpdatePreference } from 'hooks/usePreferences';
import React from 'react';
import { PreferenceName } from 'types/preference';
import { DATE_FORMAT } from 'utils/format';
import RadioList from '../controls/RadioList';
import PageControlHeader from '../PageControlHeader';

export default React.memo(function CalendarDateFormat(): JSX.Element {
  const preferences = usePreferences();
  const updatePreference = useUpdatePreference();
  const preferenceName = PreferenceName.DateFormat;
  const preferenceValue = preferences.dateFormat;

  return (
    <div className="flex flex-col">
      <PageControlHeader
        title="Date Format"
        description="Choose the way dates are displayed across the interface."
      />

      <RadioList
        items={[
          {
            title: 'Day-Month-Year',
            example: DateTime.now()
              .setZone(preferences.timezone)
              .toFormat(DATE_FORMAT.EU),
            selected: preferenceValue === DATE_FORMAT.EU,
            onSelect: () => updatePreference(preferenceName, 'DAY_MONTH'),
          },
          {
            title: 'Month-Day-Year',
            example: DateTime.now()
              .setZone(preferences.timezone)
              .toFormat(DATE_FORMAT.US),
            selected: preferenceValue === DATE_FORMAT.US,
            onSelect: () => updatePreference(preferenceName, 'MONTH_DAY'),
          },
        ]}
      />
    </div>
  );
});
