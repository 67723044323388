import classNames from 'classnames';
import IconQuestionMarkCircle from 'components/Icons/IconQuestionMarkCircle';
import { WidgetBirthdayPicker } from 'components/Profile/WidgetBirthdayPicker';
import useBirthday, { useBirthdayUpdate } from 'hooks/useBirthday';
import Button from 'joy/Button';
import { DateTime } from 'luxon';
import React from 'react';
import IconCalendar from '../icons/IconCalendar';
import PageControlHeader from '../PageControlHeader';

interface Props {
  email: string;
}

export default React.memo(function ProfileBirthday({
  email,
}: Props): JSX.Element {
  const { date, isSuggested } = useBirthday(email);
  const { setDate, removeBirthday } = useBirthdayUpdate(email);

  const parsedDate = date ? DateTime.fromISO(date) : null;

  return (
    <div className="flex flex-col">
      <PageControlHeader
        title="Birthday"
        description="Set your birthday. Only the day and month will be public."
      />

      <WidgetBirthdayPicker
        date={date}
        setDate={setDate}
        placement="bottom-start"
      >
        <div className="relative w-full text-left">
          <div
            className={classNames(
              'cursor-pointer bg-gray-100 py-3 pl-10 pr-3 text-sm outline-none transition-colors hover:bg-gray-150 focus:bg-gray-150 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:bg-gray-600',

              {
                'rounded-t-lg': isSuggested,
                'rounded-lg': !isSuggested,
              }
            )}
          >
            {parsedDate ? (
              parsedDate.toFormat('MMMM d')
            ) : (
              <span className="text-secondary">No date set</span>
            )}
          </div>

          <div className="pointer-events-none absolute top-0 left-0 flex h-full items-center justify-center px-3 text-gray-400">
            <IconCalendar />
          </div>

          {parsedDate && !isSuggested && (
            <Button
              onClick={(event: React.MouseEvent) => {
                event.stopPropagation();
                removeBirthday();
              }}
              className="text-secondary absolute top-0 right-0 flex h-full items-center justify-center px-3.5 text-s font-semibold hover:text-red-500 dark:hover:text-red-400"
            >
              Remove
            </Button>
          )}
        </div>
      </WidgetBirthdayPicker>

      {isSuggested && parsedDate && (
        <div className="-mt-px flex w-full items-center rounded-b-lg border-t border-white bg-gray-100 py-1 pl-3 pr-1 dark:border-gray-800 dark:bg-gray-700 ">
          <div className="flex items-center gap-1.5 text-gray-400">
            <IconQuestionMarkCircle />
            <p className="text-secondary text-sm font-medium">
              Someone has suggested this date. Is it correct?
            </p>
          </div>

          <div className="ml-auto flex items-center gap-1">
            <Button
              onClick={() => setDate(parsedDate.toJSDate())}
              className="flex h-8 items-center gap-1 rounded-lg px-3 text-sm font-semibold text-green-500 dark:text-green-300"
            >
              Yes
            </Button>
            <div className="h-4 w-px bg-gray-200 dark:bg-gray-600" />
            <Button
              onClick={removeBirthday}
              className="flex h-8 items-center gap-1 rounded-lg px-3 text-sm font-semibold text-red-500 dark:text-red-300"
            >
              No
            </Button>
          </div>
        </div>
      )}
    </div>
  );
});
