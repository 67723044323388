import React, { useEffect, useState } from 'react';
import { EmojiData, getEmojiDataFromNative } from 'emoji-mart';
import data from 'emoji-mart/data/apple.json';
import { isEmpty } from 'lodash';
import SwitchGroup from './controls/SwitchGroup';
import classNames from 'classnames';
import { useOwnCalendars } from 'hooks/useCalendars';
import {
  useSetVisibleCalendars,
  useVisibleCalendars,
} from 'hooks/useVisibleCalendars';
import { useUpdateCalendarMutation } from 'graphql/mutations/UpdateCalendar.graphql';
import EmojiPicker from 'components/UserPopover/EmojiPicker';
import { useCalendarsQuery } from 'graphql/queries/calendars.graphql';
import { useUserEmail } from 'contexts/auth';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import NewColorPicker from 'components/NewColorPicker';

export default React.memo(function UserCalendars(): JSX.Element | null {
  const ownCalendars = useOwnCalendars();
  const userEmail = useUserEmail();

  if (!ownCalendars.length) {
    return null;
  }

  return (
    <div className="pb-10">
      <h2 className="text-secondary letter mb-4 text-sm font-semibold uppercase">
        {userEmail}
      </h2>
      {ownCalendars.map((calendar) => (
        <UserCalendarItem
          key={`user-calendar-item-${calendar.id}`}
          calendar={calendar}
        />
      ))}
    </div>
  );
});

function UserCalendarItem({
  calendar,
}: {
  calendar: ReturnType<typeof useOwnCalendars>[0];
}): JSX.Element {
  const [optimisticCalendar, setOptimisticCalendar] = useState(() => calendar);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [emojiData, setEmojiData] = useState<EmojiData | null>(null);
  const { addCalendar, removeCalendar } = useSetVisibleCalendars();
  const [, updateCalendar] = useUpdateCalendarMutation();
  const [, refetchCalendars] = useCalendarsQuery();
  const visibleCalendars = useVisibleCalendars();
  const isEnabled = visibleCalendars.some(
    (cal) => cal.email === optimisticCalendar.id
  );
  const colorMap = EVENT_COLOR_MAP[optimisticCalendar.colorFamily];

  useEffect(() => {
    setOptimisticCalendar(calendar);
  }, [calendar]);

  useEffect(() => {
    if (isEmpty(optimisticCalendar.emoji)) {
      setEmojiData(null);
      return;
    }
    const emojiData = getEmojiDataFromNative(
      optimisticCalendar.emoji as string,
      'apple',
      data
    );
    setEmojiData(emojiData);
  }, [optimisticCalendar.emoji]);

  const toggleColorPicker = () => setIsColorPickerOpen((prev) => !prev);

  return (
    <div className="mb-3 rounded-lg bg-gray-100 py-3 pl-3 pr-5 dark:bg-gray-750">
      <SwitchGroup
        centered={true}
        selected={isEnabled}
        onSelect={() => {
          if (isEnabled) {
            removeCalendar(optimisticCalendar.id);
          } else {
            addCalendar(optimisticCalendar.id);
          }
        }}
        onlyButtonClickable={true}
      >
        <div className="flex items-center gap-px">
          <div className="relative flex">
            <div className="relative flex h-10 w-10 items-center justify-center overflow-visible rounded-l-lg bg-gray-200 dark:bg-gray-700">
              <button
                onClick={toggleColorPicker}
                className={classNames(
                  'h-5 w-5 scale-100 rounded-full border-2 text-[0px] transition-transform duration-75 active:scale-90',
                  colorMap.colorSwatchBg,
                  colorMap.border
                )}
              />
            </div>
            <div className="absolute bottom-2 -left-0.5 top-4">
              <NewColorPicker
                placement="bottom-start"
                value={optimisticCalendar.colorFamily}
                clickedOutside={() => setIsColorPickerOpen(false)}
                onChange={(color) => {
                  setOptimisticCalendar((prev) => ({
                    ...prev,
                    colorFamily: color,
                  }));
                  updateCalendar({
                    id: calendar.id,
                    colorFamily: color,
                  }).then(() =>
                    refetchCalendars({
                      requestPolicy: 'network-only',
                    })
                  );
                  setIsColorPickerOpen(false);
                }}
                expanded={isColorPickerOpen}
                isHotkeyEnabled={false}
              />
            </div>
          </div>
          <div className="flex h-10 w-10 items-center justify-center rounded-r-lg bg-gray-200 dark:bg-gray-700">
            <EmojiPicker
              variant="medium"
              emoji={emojiData}
              onEmojiChange={(val) => {
                setEmojiData(val);
                if ('native' in val) {
                  setOptimisticCalendar((prev) => ({
                    ...prev,
                    emoji: val.native,
                  }));
                  updateCalendar({
                    id: calendar.id,
                    emoji: val.native,
                  }).then(() =>
                    refetchCalendars({
                      requestPolicy: 'network-only',
                    })
                  );
                }
              }}
            />
          </div>
          <p className="px-4 text-sm font-medium">{optimisticCalendar.name}</p>
        </div>
      </SwitchGroup>
    </div>
  );
}
