import classNames from 'classnames';
import React from 'react';
import { gridPosition } from './utils';
import { DateTime } from 'luxon';
import { isToday, isWeekend } from 'utils/time';
import { useCalendarModeValue } from 'hooks/useCalendarMode';

interface Props {
  index: number;
  date: DateTime;
}

export default function GridDay({ index, date }: Props) {
  const calendarMode = useCalendarModeValue();
  const active = calendarMode !== 'selecting-recurring-slots' && isToday(date);

  return (
    <div
      className="relative flex items-center justify-center"
      style={{
        gridRow: 1,
        gridColumn: index + gridPosition.columnStart,
      }}
    >
      {active && (
        <div className="absolute top-1">
          <div className="h-0.5 w-4 rounded-full bg-rose-500 dark:bg-rose-400" />
        </div>
      )}

      <div
        className={classNames(
          'flex items-center gap-1 text-sm font-semibold leading-none',
          {
            'text-secondary': !active && isWeekend(date),
            'text-primary': !active && !isWeekend(date),
            'text-rose-500 dark:text-rose-400': active,
          }
        )}
      >
        <span>{date.toFormat('EEE')}</span>
        {calendarMode !== 'selecting-recurring-slots' && date.toFormat('d')}
      </div>
    </div>
  );
}
