/* 887aab5a487a3d6ca750d18e22d61f3aa895dee3
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SingleEventQueryVariables = Types.Exact<{
  eventId: Types.Scalars['String'];
  calendarId: Types.Scalars['String'];
}>;


export type SingleEventQuery = { __typename?: 'query_root', singleEvent?: { __typename?: 'NewEvent', calendarId: string, description?: string | null, title: string, id: string, createdAt: string, doneAt?: string | null, doneBy?: string | null, location?: string | null, colorFamily?: Types.ColorFamily | null, rsvp: Types.NewEventRsvpEnum, isOwnEvent: boolean, canEdit: boolean, isSelfAsAttendee: boolean, belongsToUserCalendar: boolean, eventType?: string | null, recurringEventId?: string | null, recurrenceRules?: Array<string> | null, startAt: any, endAt: any, isAllDay: boolean, prevStartAt: any, prevEndAt: any, status: string, visibility: Types.NewEventVisibilityEnum, attendees: Array<{ __typename?: 'NewEventAttendee', avatar?: string | null, displayName?: string | null, firstName?: string | null, id: string, lastName?: string | null, RSVP: Types.NewEventRsvpEnum, email: string, organizer: boolean, responseStatus: Types.NewEventAttendeeResponseStatusEnum }>, creator?: { __typename?: 'EventCreator', id?: string | null, self?: boolean | null } | null, videoConferences: Array<{ __typename?: 'NewEventVideoConference', link: string, provider: Types.NewVideoConferenceProvider }> } | null };

export type EventFragmentFragment = { __typename?: 'NewEvent', calendarId: string, description?: string | null, title: string, id: string, createdAt: string, doneAt?: string | null, doneBy?: string | null, location?: string | null, colorFamily?: Types.ColorFamily | null, rsvp: Types.NewEventRsvpEnum, isOwnEvent: boolean, canEdit: boolean, isSelfAsAttendee: boolean, belongsToUserCalendar: boolean, eventType?: string | null, recurringEventId?: string | null, recurrenceRules?: Array<string> | null, startAt: any, endAt: any, isAllDay: boolean, prevStartAt: any, prevEndAt: any, status: string, visibility: Types.NewEventVisibilityEnum, attendees: Array<{ __typename?: 'NewEventAttendee', avatar?: string | null, displayName?: string | null, firstName?: string | null, id: string, lastName?: string | null, RSVP: Types.NewEventRsvpEnum, email: string, organizer: boolean, responseStatus: Types.NewEventAttendeeResponseStatusEnum }>, creator?: { __typename?: 'EventCreator', id?: string | null, self?: boolean | null } | null, videoConferences: Array<{ __typename?: 'NewEventVideoConference', link: string, provider: Types.NewVideoConferenceProvider }> };

export const EventFragmentFragmentDoc = gql`
    fragment EventFragment on NewEvent {
  attendees {
    avatar
    displayName
    firstName
    id
    lastName
    RSVP
    email
    organizer
    responseStatus
  }
  calendarId
  description
  title
  id
  createdAt
  creator {
    id
    self
  }
  doneAt
  doneBy
  location
  colorFamily
  rsvp
  isOwnEvent
  canEdit
  isSelfAsAttendee
  belongsToUserCalendar
  eventType
  recurringEventId
  recurrenceRules
  startAt
  endAt
  isAllDay
  prevStartAt
  prevEndAt
  status
  videoConferences {
    link
    provider
  }
  visibility
}
    `;
export const SingleEventDocument = gql`
    query SingleEvent($eventId: String!, $calendarId: String!) {
  singleEvent(where: {eventId: $eventId, calendarId: $calendarId}) {
    ...EventFragment
  }
}
    ${EventFragmentFragmentDoc}`;

export function useSingleEventQuery(options: Omit<Urql.UseQueryArgs<SingleEventQueryVariables>, 'query'>) {
  return Urql.useQuery<SingleEventQuery>({ query: SingleEventDocument, ...options });
};