import { useAtomValue } from 'jotai/utils';
import React from 'react';
import ExternalLink from '../controls/ExternalLink';
import Input from '../controls/Input';
import PageControlHeader from '../PageControlHeader';
import { isOpenAroundSectionAtom, useAround } from '../useSettings';

export default React.memo(
  function EventAroundLinkComponent(): JSX.Element | null {
    const isAroundSectionOpen = useAtomValue(isOpenAroundSectionAtom);
    const { aroundLink, onSubmit } = useAround();

    if (!isAroundSectionOpen && !aroundLink) return null;

    return (
      <div className="flex flex-col">
        <PageControlHeader
          title="Around link"
          description={
            <>
              Your meeting link for{' '}
              <ExternalLink href="https://www.around.co/">Around</ExternalLink>.
              It&#39;ll be available in the event location.
            </>
          }
        />

        <Input
          placeholder="e.g https://meet.around.co/r/amie"
          value={aroundLink || ''}
          onSubmit={onSubmit}
        />
      </div>
    );
  }
);
